/**
 * Created by nick on 21/01/2016.
 */

class GraphicController
{
	public update(delta:number):void {}
	public dispose():void {}
}

export = GraphicController;