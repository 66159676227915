import Backbone = require("backbone");
import _ = require("underscore");
import $ = require("jquery");
import Project = require("../models/Project");
import Constants = require("../setup/Constants");
import UIUtils = require("../utils/UIUtils");
import ViewSignals = require("../setup/ViewSignals");
import View = Backbone.View;

var template = require("../../templates/project-picture.mustache");
import spinnerSVGJSON from "../../svg/spinner-svg.json";

const IMAGE_INTERVAL = 3000;

const MODEL_ATTR_NUM_IMAGES = "numImages";

class ProjectPictureView extends Backbone.View<Project>
{
	private _initialImageLoaded = false;
	private _timingImages = false;
	private _imageIndex = 0;
	private _frameAnimID:number;
	private _animTimestamp:number;
	private _timeSinceLastImage = 0;
	private _spinnerAnim;
	private _$imageContainer;
	private _$spinner;

	// Initialisation

	constructor(options?)
	{
		super(_.extend(options,
			{
				tagName: "a",
				className:
					`picture picture-code-${options.model.get("code")} ${options.model.get("mobile")? "mobile" : ""}`,
				attributes: {href: `#projects/${options.model.get("code")}`}
		}));

		_.bindAll(this, "loadImage", "onAnimateFrame");
	}

	public initialize():void
	{
		ViewSignals.viewportUpdate.add(this.onViewportUpdate, this);
	}

	// Render

	public render()
	{
		this.$el.append(template());
		this._$imageContainer = this.$el.find(".image-container");
		this._$spinner = this._$imageContainer.find(".spinner-container");
		return this;
	}

	// Public

	public loadImage():Q.Promise
	{
		var deferred = Q.defer();

		var $img = $("<img>");

		$img.on(
            'load',
            () => {
                this._$imageContainer.removeClass("show-background");

                this._$imageContainer.append($img);

                // We have to wait a little before changing the image element's opacity, otherwise the transition
                // won't run
                UIUtils.delayCSSChange(() =>
                {

                    $img
                        .on(Constants.CSS_EVENTS_TRANSITION_END, () =>
                        {
                            // If there's more than one image in the container, remove the one at the bottom
                            var children = this._$imageContainer.children();

                            if(children.length > 1)
                            {
                                if(this._$spinner)
                                {
                                    this._spinnerAnim.stop();
                                    this._spinnerAnim = null;
                                    this._$spinner = null;
                                }

                                children.eq(0).remove();
                            }
                        })
                        .css("opacity", 1);
                });

                deferred.resolve(null);
            })
            .attr("src", `images/projects/${this.model.get("code")}/${this._imageIndex}.png`);


		return deferred.promise;
	}

	public deHighlight(deHighlight:boolean):void
	{
		const CSS_CLASS_DEHIGHLIGHT = "dehighlight";

		if(deHighlight)
			this.$el.addClass(CSS_CLASS_DEHIGHLIGHT);
		else
			this.$el.removeClass(CSS_CLASS_DEHIGHLIGHT);
	}

	public timeImages(time:boolean):void
	{
		this._timingImages = time;

		if(time)
			this._frameAnimID = requestAnimationFrame(this.onAnimateFrame);
		else
			cancelAnimationFrame(this._frameAnimID);
	}

	// Private

	private initSpinner():void
	{
		var svgJSON = spinnerSVGJSON;

		const CSS_CLASS_SPINNER = "spinner";
		const SVG_WIDTH = 94;
		const SVG_HEIGHT = 94;
		const SVG_FPS = 5;

		this._spinnerAnim = new SVGAnim(svgJSON, SVG_WIDTH, SVG_HEIGHT, SVG_FPS);
		var $svg = UIUtils.initCreatedSVG(SVG_WIDTH, SVG_HEIGHT, 100, "xMinYMin");
		$svg[0].setAttribute("class", CSS_CLASS_SPINNER);
		this.$el.find(".spinner-container").append($svg);
		this._spinnerAnim.play();
	}

	private loadNextImage():Q.Promise
	{
		this._imageIndex = (this._imageIndex + 1) % this.model.get(MODEL_ATTR_NUM_IMAGES);
		return this.loadImage();
	}

	// Handlers

	private onAnimateFrame(timestamp):void
	{
		if(this._animTimestamp)
		{
			var delta = timestamp - this._animTimestamp;

			if(this._timingImages)
			{
				this._timeSinceLastImage += delta;

				if(this._timeSinceLastImage > IMAGE_INTERVAL)
				{
					this._timingImages = false;
					this._timeSinceLastImage = 0;
					this.loadNextImage().then(() => { this.timeImages(true); });
				}
			}
		}

		this._animTimestamp = timestamp;
		this._frameAnimID = requestAnimationFrame(this.onAnimateFrame);
	}

	private onViewportUpdate(viewportTop:number, viewportBottom:number):void
	{
		if(!this._initialImageLoaded)
		{
			var top = this.$el.offset().top;

			if(top > viewportTop && top < viewportBottom)
			{
				this._initialImageLoaded = true;

				if(this.model.get(MODEL_ATTR_NUM_IMAGES))
				{
					this.initSpinner();
					this.loadImage();
				}
			}
		}
	}

	// Destruction

	public remove():View<any>
	{
		ViewSignals.viewportUpdate.remove(this.onViewportUpdate, this);
		return super.remove();
	}
}

export = ProjectPictureView;