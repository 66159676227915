import $ = require("jquery");
import ViewSignals = require("../../setup/ViewSignals");
import ScreenSize = require("../../types/ScreenSize");
import GraphicController = require("./GraphicController");
import Constants = require("../../setup/Constants");
import UIUtils = require("../../utils/UIUtils");

const FLY_BY_INTERVAL = 10000;
const CSS_PROP_TRANSFORM = "transform";

class CometController extends GraphicController
{
	private _flyingBy = false;
	private _minX:number;
	private _timeSinceLastFlyBy = 0;
	private _windowWidth:number;
	private _windowHeight:number;
	private _screenSize:ScreenSize;
	private _transformObject = {x: 0, y: 0, rotation: 0};

	constructor(private _svgAnim, private _$svg)
	{
		super();
		ViewSignals.screenSizeChange.add(this.onScreenSizeChange, this);
	}

	public update(delta):void
	{
		const CSS_CLASS_FLY_TWEEN = "fly-tween";

		if(!this._flyingBy)
		{
			this._timeSinceLastFlyBy += delta;

			if(this._timeSinceLastFlyBy > FLY_BY_INTERVAL)
			{
				if(this._screenSize >= ScreenSize.MEDIUM) this._svgAnim.play();
				this._timeSinceLastFlyBy = 0;
				this.measure();
				this.positionComet();
				this._transformObject.x = this._windowWidth / 2;
				this._transformObject.y -= this._windowHeight / 50;
				this._transformObject.rotation = 0;
				this._flyingBy = true;

				UIUtils.delayCSSChange(() =>
				{
					const CSS_ATTR_CLASS = "class";

					var origClassList = this._$svg.attr(CSS_ATTR_CLASS);
					var flightHalfIndex = 0;

					// We are setting the class list here using attr instead of addClass and removeClass because this
					// version of JQuery doesn't support these methods on SVGs.
					this._$svg
						.css("visibility", "visible")
						.attr(CSS_ATTR_CLASS, origClassList + " " + CSS_CLASS_FLY_TWEEN)
						.on(Constants.CSS_EVENTS_TRANSITION_END, () =>
						{
							switch(flightHalfIndex)
							{
								case 0:
									this._transformObject.x = this._windowWidth;
									this._transformObject.y += this._windowHeight / 50;
									this._transformObject.rotation = 10;
									this._$svg.css(
										CSS_PROP_TRANSFORM,
										`translate(${this._transformObject.x}px, ${this._transformObject.y}px) ` +
										`rotate(${this._transformObject.rotation}deg)`);
									break;
								case 1:
									this._$svg
										.attr(CSS_ATTR_CLASS, origClassList)
										.off(Constants.CSS_EVENTS_TRANSITION_END);
									this._svgAnim.pause();
									this._flyingBy = false;
							}

							flightHalfIndex ++;
						})
						.css(CSS_PROP_TRANSFORM,
							`translate(${this._transformObject.x}px, ${this._transformObject.y}px) ` +
							`rotate(${this._transformObject.rotation}deg)`);
				});
			}
		}
	}

	private measure():void
	{
		this._windowWidth = $(window).width();
		this._windowHeight = $(window).height();
		this._minX = -this._$svg.width();
	}

	private positionComet():void
	{
		this._transformObject.x = this._minX;
		this._transformObject.y = Math.random() * this._windowHeight * 0.3;
		this._transformObject.rotation = -10;

		this._$svg.css(
			CSS_PROP_TRANSFORM,
			`translate(${this._transformObject.x}px, ${this._transformObject.y}px) ` +
			`rotate(${this._transformObject.rotation}deg)`);
	}

	private onScreenSizeChange(screenSize:ScreenSize):void
	{
		this._screenSize = screenSize;
	}

	public dispose():void
	{
		ViewSignals.screenSizeChange.remove(this.onScreenSizeChange);
	}
}

export = CometController;