import _ = require("underscore");
import Backbone = require("backbone");
import Constants = require("../setup/Constants");
import ViewSignals = require("../setup/ViewSignals");

var template = require("../../templates/project-details.mustache");

const CSS_TWEEN_HIDE_SHOW = "tween-hide-show";

class ProjectDetailsView extends Backbone.View<any>
{
	constructor(options?)
	{
		super(_.extend(options,
			{
				className: "project-details",
				events: {
					"click .watch-showreel": "onWatchShowreel"
				}
			}));
	}

	public render():ProjectDetailsView
	{

		const modelJSON = this.model.toJSON();
		const client = modelJSON.client;
		const url = modelJSON.url;
		const data =
			_.extend(
				modelJSON,
				{
					webURL: url? url.web : null,
					appStoreURL: url? url.appStore : null,
					clientTitle: client? client.title : null,
					clientURL: client? client.url : null
				}
			);

		this.$el.append(template(data));

		if(modelJSON.technologies.length === 0)
			this.$el.find(".technologiesLabel").hide();

		if(modelJSON.platforms.length === 0)
			this.$el.find(".platformsLabel").hide();

		if(!client)
			this.$el.find(".client").hide();

		return this;
	}

	public show(show:boolean):Q.Promise
	{
		var deferred = Q.defer();

		enum TweenState
		{
			HEIGHT,
			PADDING
		}

		var tweenState = TweenState.HEIGHT;

		var transitionCompleteHandler = () =>
		{
			switch(tweenState)
			{
				case TweenState.HEIGHT:

					tweenState = TweenState.PADDING;
					this.$el
						.css(Constants.CSS_PROP_PADDING_TOP, show? Constants.CSS_STANDARD_MARGIN : 0)
						.css(Constants.CSS_PROP_PADDING_BOTTOM, show? Constants.CSS_STANDARD_MARGIN : 0);
					break;

				case TweenState.PADDING:

					this.$el
						.removeClass(CSS_TWEEN_HIDE_SHOW)
						.off(Constants.CSS_EVENTS_TRANSITION_END);

					deferred.resolve(null);
			}
		};

		if(show)
		{
			var intendedHeight = this.$el.outerHeight();

			this.$el
				.outerHeight(0)
				.addClass(CSS_TWEEN_HIDE_SHOW)
				.on(Constants.CSS_EVENTS_TRANSITION_END, transitionCompleteHandler)
				.outerHeight(intendedHeight);
		}
		else
		{
			this.$el
				.addClass(CSS_TWEEN_HIDE_SHOW)
				.on(Constants.CSS_EVENTS_TRANSITION_END, transitionCompleteHandler)
				.outerHeight(0);
		}

		return deferred.promise;
	}

	private onWatchShowreel(event):void
	{
		event.stopPropagation();
		ViewSignals.showVideoPanel.dispatch(true);
	}
}

export = ProjectDetailsView;