import _ = require("underscore");
import $ = require("jquery");
import Backbone = require("backbone");
import UIUtils = require("../utils/UIUtils");
import TapeReelController = require("../ui/controllers/TapeReelController");
import SectionView = require("./SectionView");
import BotController = require("../ui/controllers/BotController");
import ParallaxController = require("../ui/controllers/ParallaxController");
import ParallaxController2 = require("../ui/controllers/ParallaxController2");
import CommandSignals = require("../setup/CommandSignals");
import ViewSignals = require("../setup/ViewSignals");
import SectionType = require("../types/SectionType");
import GraphicController = require("../ui/controllers/GraphicController");
import View = Backbone.View;

var template = require("../../templates/contact-section.mustache");

import machineLEDsSVGJSON from "../../svg/machine-leds-svg.json";

class ContactSectionView extends SectionView
{
	private _numAnimControllers = 0;
	private _frameAnimID:number;
	private _animTimestamp:number;
	private _animControllers:GraphicController[] = [];
	private _tapeReelControllers:TapeReelController[] = [];
	private _articleParallaxController:ParallaxController2;

	// Initialisation

	constructor()
	{
		super(
			{
				className: "contact-section"
			}
		);
	}

	initialize()
	{
		_.bindAll(this, "onContentReady", "onAnimateFrame");
		ViewSignals.populateContent.add(this.onContentReady);
		super.initialize();
	}

	// Render

	public render():ContactSectionView
	{
		this.$el.append(template());
		this.initSVG();
		this.initTapeReels();
		this.initBot();
		return this;
	}

	// Handlers

	public onScrollUpdate(scrollProportion:number):void
	{
		if(this._articleParallaxController) this._articleParallaxController.update(scrollProportion);
	}

	public onSectionInView():void
	{
		this._tapeReelControllers.forEach((controller:TapeReelController) => { controller.activate(); });
		this._frameAnimID = requestAnimationFrame(this.onAnimateFrame);
	}

	public onSectionOutOfView():void
	{
		this._tapeReelControllers.forEach((controller:TapeReelController) => { controller.deactive(); });
		cancelAnimationFrame(this._frameAnimID);
	}

	private onAnimateFrame(timestamp):void
	{
		if(this._animTimestamp)
		{
			var delta = timestamp - this._animTimestamp;
			for(var i = this._numAnimControllers; -- i >= 0; ) this._animControllers[i].update(delta);
		}

		this._animTimestamp = timestamp;
		this._frameAnimID = requestAnimationFrame(this.onAnimateFrame);
	}

	private onContentReady(sectionType:SectionType, html:string):void
	{
		if(sectionType === SectionType.CONTACT)
		{
			var $article = this.$el.find("article");
			$article.html(html);
			this._articleParallaxController = new ParallaxController2($article, -0.3, 1);
		}
	}

	// Private

	private initSVG():void
	{
        // @ts-ignore
		this.initMachineLEDs(machineLEDsSVGJSON);
	}

	private initMachineLEDs(svgJSON):void
	{
		const CSS_CLASS_MACHINE_LEDS = "machine-leds";
		const SVG_WIDTH = 222;
		const SVG_HEIGHT = 222;
		const SVG_FPS = 1;

		var svgAnim = new SVGAnim(svgJSON, SVG_WIDTH, SVG_HEIGHT, SVG_FPS);
		var $svg = UIUtils.initCreatedSVG(SVG_WIDTH, SVG_HEIGHT, 100, "xMinYMin");
		$svg[0].setAttribute("class", CSS_CLASS_MACHINE_LEDS);
		this.$el.find(".content > .machine-led-container").append($svg);
		svgAnim.play();
	}

	private initTapeReels():void
	{
		this.$el.find(".tape-reel").each((index:number, element:Element) =>
		{
			this._tapeReelControllers.push(new TapeReelController($(element)));
		});
	}

	private initBot():void
	{
		this.addAnimController(new BotController(this.$el.find(".bot")));
	}

	private addAnimController(controller):void
	{
		this._animControllers.push(controller);
		this._numAnimControllers = this._animControllers.length;
	}

	// Overrides

	public get sectionURL():string
	{
		return SectionType[SectionType.CONTACT].toLowerCase();
	}

	// Destruction

	public remove():View<any>
	{
		this._animControllers.forEach((controller:GraphicController) => { controller.dispose(); });
		ViewSignals.populateContent.remove(this.onContentReady);
		return super.remove();
	}
}

export = ContactSectionView;