/**
 * Created by nick on 21/12/2015.
 */

import CommandSignals = require("../setup/CommandSignals");
import ViewSignals = require("../setup/ViewSignals");
import SectionType = require("../types/SectionType");
import Utils = require("../utils/Utils");

class InitCommand
{
    public execute()
    {
	    CommandSignals.initSections.dispatch();
        CommandSignals.initNav.dispatch();
	    CommandSignals.initRouter.dispatch();
        ViewSignals.initUIComponents.dispatch();

	    Utils.dispatchAsyncSignal(CommandSignals.loadSectionContent, {sectionType: SectionType.ABOUT}).then(() =>
	    {
		    return Utils.dispatchAsyncSignal(CommandSignals.loadDataMaps);
	    })
	    .then(() =>
	    {
		    return Utils.dispatchAsyncSignal(CommandSignals.loadProjects);
	    }).then(() =>
	    {
		    return Utils.dispatchAsyncSignal(CommandSignals.loadSectionContent, {sectionType: SectionType.CONTACT});
	    }).then(() =>
	    {
		    ViewSignals.initComplete.dispatch();
	    });
    }
}

export = InitCommand;