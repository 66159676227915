import $ = require("jquery");
import Constants = require("../../setup/Constants");

const CSS_CLASS_TWEEN_Y = "tween-y";

class ParallaxController2
{
	private _scrollProportion = 0;
	private _windowHeight = $(window).height();
	private _transformObject = {dy: 0};

	constructor(private _$target, private _entranceScrollProportion, private _exitScrollProportion)
	{
		this.translateY(this._windowHeight);
		_$target.addClass(CSS_CLASS_TWEEN_Y);
	}

	public update(scrollProportion:number):void
	{
		if((this._scrollProportion < this._entranceScrollProportion &&
			scrollProportion >= this._entranceScrollProportion) ||
			(this._scrollProportion > this._exitScrollProportion && scrollProportion <= this._exitScrollProportion))
			this.translateY(0);
		else if(this._scrollProportion < this._exitScrollProportion && scrollProportion >= this._exitScrollProportion)
			this.translateY(-this._windowHeight);
		else if(this._scrollProportion > this._entranceScrollProportion &&
			scrollProportion <= this._entranceScrollProportion)
			this.translateY(this._windowHeight);

		this._scrollProportion = scrollProportion;
	}

	private translateY(dy:number):void
	{
		this._transformObject.dy = dy;

		this._$target
			.css(Constants.CSS_PROP_TRANSFORM, `translate(0, ${dy}px)`)
	}
}

export = ParallaxController2;