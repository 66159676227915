import DataLoadService = require("../services/DataLoadService");
import ViewSignals = require("../setup/ViewSignals");
import ProjectCollection = require("../models/ProjectCollection");

class LoadProjectsCommand
{
	// Injected parameters
	public deferredTask:Q.Deferred = null;

	// Dependency injections
	public projectCollection:ProjectCollection = null;

	public execute():void
	{
		this.projectCollection.fetch(
			{
				success: (collection) =>
				{
					ViewSignals.populateProjects.dispatch(collection);
					this.deferredTask.resolve(null);
				}
			});
	}
}

export = LoadProjectsCommand;