import _ = require("underscore");
import $ = require("jquery");
import ScreenSize = require("../types/ScreenSize");
import ViewSignals = require("../setup/ViewSignals");

const QUERY_SMALL = "(min-width: 768px)";
const QUERY_MEDIUM = "(min-width: 992px)";
const QUERY_LARGE = "(min-width: 1200px)";

class ScreenSizeMonitor
{
	private _mediaQueries = [];

	constructor()
	{
		_.bindAll(this, "onQueryStateChange");

		[
			QUERY_SMALL,
			QUERY_MEDIUM,
			QUERY_LARGE
		].forEach((queryString:string) =>
		{
			var query = window.matchMedia(queryString);
			query.addListener(this.onQueryStateChange)
			this._mediaQueries.push(query);
		});

		this.dispatchScreenSizeChange();
	}

	private onQueryStateChange():void
	{
		this.dispatchScreenSizeChange();
	}

	private dispatchScreenSizeChange():void
	{
		var matchFound = false;

		for(var i = this._mediaQueries.length; -- i >= 0 && !matchFound; )
		{
			if(this._mediaQueries[i].matches)
			{
				matchFound = true;
				ViewSignals.screenSizeChange.dispatch(i + 1);
			}
		}

		if(!matchFound) ViewSignals.screenSizeChange.dispatch(ScreenSize.EXTRA_SMALL);
	}
}

export = ScreenSizeMonitor;