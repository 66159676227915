/**
 * Created by nick on 03/01/2016.
 */

import ViewSignals = require("../setup/ViewSignals");

class InitNavigationCommand
{
    public execute()
    {
        ViewSignals.initNav.dispatch();
    }
}

export = InitNavigationCommand;