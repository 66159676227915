import Backbone = require("backbone");
import CommandSignals = require("../setup/CommandSignals");
import ViewSignals = require("../setup/ViewSignals");
import ProjectCollection = require("../models/ProjectCollection");
import SectionType = require("../types/SectionType");

enum Route
{
	LOGO,
	ABOUT,
	PROJECTS,
	PROJECT_DETAILS,
	CONTACT
}

class AppRouter extends Backbone.Router
{
	routes =
	{
		"projects/(:projectID)": this.onRouteToProject,
		"about": this.onRouteToAbout,
		"projects": this.onRouteToProjects,
		"contact": this.onRouteToContact,
		"logo": this.onRouteToLogo
	};

	// Dependency injections
	public projectCollection:ProjectCollection;

	private _currentRoute:Route;

	constructor()
	{
		super();
		(<any>this)._bindRoutes();
	}

	public initialize()
	{
		this.projectCollection = null;
	}

	private onRouteToLogo():void
	{
		this._currentRoute = Route.LOGO;
		ViewSignals.goToSection.dispatch(SectionType.LOGO);
	}

	private onRouteToProjects():void
	{
		ViewSignals.goToSection.dispatch(SectionType.PROJECTS, this._currentRoute === Route.PROJECT_DETAILS);
		this._currentRoute = Route.PROJECTS;
	}

	private onRouteToProject(projectCode:string):void
	{
		this._currentRoute = Route.PROJECT_DETAILS;

		// If projects have loaded, load the details for the specified project. If coming into the site cold, projects
		// won't have loaded, in which case we first go to the projects section, which will in turn cause projects to be
		// loaded, and we let it know that the details of the specified project should be shown.
		if(this.projectCollection.length)
		{
			var project = this.projectCollection.findWhere({code: projectCode});
			ViewSignals.highlightProject.dispatch(project);
			CommandSignals.loadProjectDetails.dispatch({project: project});
		}
		else
		{
			ViewSignals.goToSection.dispatch(SectionType.PROJECTS, {projectCode: projectCode});
		}
	}

	private onRouteToAbout():void
	{
		this._currentRoute = Route.ABOUT;
		ViewSignals.goToSection.dispatch(SectionType.ABOUT);
	}

	private onRouteToContact():void
	{
		this._currentRoute = Route.CONTACT;
		ViewSignals.goToSection.dispatch(SectionType.CONTACT);
	}
}

export = AppRouter;