import Backbone = require("backbone");
import _ = require("underscore");

var template = require("../../templates/project-picture.mustache");

class PictureBlankView extends Backbone.View<any>
{
	constructor()
	{
		super({className: "blank"});
	}

	public render()
	{
		this.$el.append(template());
		return this;
	}
}

export = PictureBlankView;