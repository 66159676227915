import Backbone = require("backbone");
import MainRouter = require("../setup/MainRouter");
import MVCContext = require("../mvc/MVCContext");

/**
 * Created by nick on 13/01/2016.
 */

class InitRouterCommand
{
	public execute():void
	{
		Backbone.history.start( /* {pushState: true} */ );
	}
}

export = InitRouterCommand;