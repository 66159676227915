import MainRouter = require("../setup/MainRouter");

/**
 * Created by nick on 20/01/2016.
 */

class UpdateURLCommand
{
	// Dependency injections
	public router:MainRouter = null;

	// Parameter injections
	public url:string = null;

	public execute():void
	{
		this.router.navigate(this.url);
	}
}

export = UpdateURLCommand;