import Constants = require("../../setup/Constants");
import GraphicController = require("./GraphicController");
import UIUtils = require("../../utils/UIUtils");

const CSS_CLASS_PLAY = "play";

class TapeReelController
{
	private _playing = true;
	private _active = false;

	constructor(private _$target)
	{
		this._$target.on(Constants.CSS_EVENTS_TRANSITION_END, () =>
		{
			if(this._playing)
			{
				this._playing = false;
				if(this._active) this.rewind();
			}
			else
			{
				this._playing = true;
				if(this._active) this.play();
			}
		});
	}

	public activate():void
	{
		this._active = true;
		if(this._playing) this.play(); else this.rewind();
	}

	public deactive():void
	{
		this._active = false;
	}

	private play():void
	{
		UIUtils.delayCSSChange(() =>
		{
			this._$target.addClass(CSS_CLASS_PLAY);
		});
	}

	private rewind():void
	{
		this._$target.removeClass(CSS_CLASS_PLAY);
	}
}

export = TapeReelController;