import Signal = require("../signals/Signal");
/**
 * Created by nick on 25/12/2015.
 */

var ViewSignals =
{
    initNav: new Signal(),
    initSections: new Signal(),
    initUIComponents: new Signal(),
	initComplete: new Signal(),
	populateContent: new Signal(),
    populateProjects: new Signal(),
    populateProjectDetails: new Signal(),
    highlightProject: new Signal(),
    goToSection: new Signal(),
    notifyResize: new Signal(),
    viewportUpdate: new Signal(),
	scrollToElement: new Signal(),
    screenSizeChange: new Signal(),
    switchingProjectDetails: new Signal(),
	autoScrollingToSection: new Signal(),
	showNav: new Signal(),
	appClick: new Signal(),
    showVideoPanel: new Signal()
};

ViewSignals.initSections.memorize = true;
ViewSignals.initNav.memorize = true;
ViewSignals.screenSizeChange.memorize = true;
ViewSignals.goToSection.memorize = true;

export = ViewSignals;