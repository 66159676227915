import $ = require("jquery");
import Application = require("./view/Application");
import MVCSetup = require("./setup/MVCSetup");

$(() => {
	console.log("Kind of ready to rock. Almost.");

	window["Q"] = require("Q");

	new MVCSetup().setUp();

	FastClick.attach(document.body);

	var app = new Application();
	$(document).find("body").append(app.render().$el);
	app.postRender();
});
