import Backbone = require("backbone");

/**
 * Created by nick on 11/01/2016.
 */

class Project extends Backbone.Model
{

}

export = Project;