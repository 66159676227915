import Backbone = require("backbone");
import ProjectPictureView = require("./ProjectPictureView");
import Project = require("../models/Project");
import _ = require("underscore");
import PictureBlankView = require("./PictureBlankView");
import View = Backbone.View;

/**
 * Created by nick on 11/01/2016.
 */

class ProjectRowView extends Backbone.View<any>
{
	private _pictureViews:ProjectPictureView[];
	private _numViewsPerRow:number;

	constructor(options?)
	{
		super(_.extend(options, {
			className: "project-row"
		}));

		_.bindAll(this, "loadImages");
		this._pictureViews = [];
		this._numViewsPerRow = options.numViewsPerRow;
	}

	public render()
	{
		this.collection.forEach((project:Project) =>
		{
			var pictureView = new ProjectPictureView({model: project});
			this.$el.append(pictureView.render().$el);
			this._pictureViews.push(pictureView);
		});

		var numProjects = this.collection.length;

		for(var i = this._numViewsPerRow - numProjects; -- i >= 0; )
			this.$el.append(new PictureBlankView().render().$el);

		return this;
	}

	public loadImages():Q.Promise
	{
		var imageLoadPromise;
		var imageLoadDeferred = Q.defer();

		this._pictureViews.forEach((pictureView:ProjectPictureView) =>
		{
			if(imageLoadPromise)
				imageLoadPromise = imageLoadPromise.then(pictureView.loadImage);
			else
				imageLoadPromise = pictureView.loadImage();
		});

		imageLoadPromise = imageLoadPromise.then(() =>
		{
			imageLoadDeferred.resolve(null);
		});

		return imageLoadDeferred.promise;
	}

	public get pictureViews():ProjectPictureView[]
	{
		return this._pictureViews;
	}

	public remove():View<any>
	{
		this._pictureViews.forEach((pictureView:ProjectPictureView) => pictureView.remove());
		return super.remove();
	}
}

export = ProjectRowView;