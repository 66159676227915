import $ = require("jquery");

class DataLoadService
{
    public load(url:string):Q.Promise
    {
        var deferred = Q.defer();

        $.get(url, function(data)
        {
	        deferred.resolve(data);
        });

        return deferred.promise;
    }
}

export = DataLoadService;