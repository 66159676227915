import Backbone = require("backbone");
import _ = require("underscore");

const template = require("../../templates/videopanel.mustache");

class VideoPanel extends Backbone.View<any>
{
    constructor()
    {
        super({
            className: "video-panel"
        });
    }

    public render():VideoPanel
    {
        this.$el.append(template());
        return this;
    }
}

export = VideoPanel;