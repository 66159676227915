import Backbone = require("backbone");
import _ = require("underscore");
import $ = require("jquery");
import SectionView = require("./SectionView");
import ProjectRowView = require("./ProjectRowView");
import ViewSignals = require("../setup/ViewSignals");
import CommandSignals = require("../setup/CommandSignals");
import Project = require("../models/Project");
import ProjectCollection = require("../models/ProjectCollection");
import ProjectDetailsView = require("./ProjectDetailsView");
import ProjectPictureView = require("./ProjectPictureView");
import SectionType = require("../types/SectionType");
import ScreenSize = require("../types/ScreenSize");

var template = require("../../templates/projects-section.mustache");

class ProjectsView extends SectionView
{
	/** If set, indicates the code of a project whose details are to be loaded and displayed after projects have loaded.
	 */
	private _cuedProjectCode:string;

	private _$content;
	private _screenSize:ScreenSize;
	private _rowViews:ProjectRowView[] = [];
	private _pictureViews:ProjectPictureView[] = [];
	private _detailsView:ProjectDetailsView;

	// Initialisation

	constructor()
	{
		super({
			className: "projects-section",
			events:
			{
				"click .scroll-arrow": "onScrollArrowClick"
			}
		});
	}

	public initialize():void
	{
		ViewSignals.screenSizeChange.add(this.onScreenSizeChange, this);
		ViewSignals.highlightProject.add(this.onHighlightProject, this);
		ViewSignals.populateProjectDetails.add(this.onPopulateProjectDetails, this);

		ViewSignals.populateProjects.addOnce(this.onPopulateProjects, this);
		ViewSignals.initComplete.addOnce(this.onInitComplete, this);

		super.initialize();
	}

	// Render

	public render():ProjectsView
	{
		this.$el.append(template());
		this._$content = this.$el.find(".content");
		return this;
	}

	// Handlers

	public onSectionOutOfView():void
	{
		if(this._detailsView)
		{
			this.hideProjectDetails();
			this.highlightPictureView(null);
		}
	}

	private onScreenSizeChange(screenSize:ScreenSize):void
	{
		var formatChanged = false;

		if((this._screenSize === ScreenSize.SMALL && screenSize === ScreenSize.MEDIUM) ||
			(this._screenSize === ScreenSize.MEDIUM && screenSize === ScreenSize.SMALL))
			formatChanged = true;

		this._screenSize = screenSize;

		if(formatChanged && this.collection)
		{
			this._rowViews.forEach((rowView:ProjectRowView) => rowView.remove());
			this._pictureViews.splice(0, this._pictureViews.length);
			this._rowViews.splice(0, this._rowViews.length);
			this.populateProjects();

			// Fire a viewport update to make each of the visible recreated picture views start loading their
			// first pictures
			this.dispatchViewportUpdate();
		}
	}

	private dispatchViewportUpdate():void
	{
		var scrollOffset = window.pageYOffset;
		ViewSignals.viewportUpdate.dispatch(scrollOffset, scrollOffset + $(window).height());
	}

	private onHighlightProject(project:Project):void
	{
		var pictureView = this._pictureViews[this.collection.indexOf(project)];
		this.highlightPictureView(pictureView);
	}

	private onGoToSection(sectionType:SectionType, options?:any):void
	{
		if(sectionType === SectionType.PROJECTS)
		{
			this._cuedProjectCode = (options && options.projectCode) || null;

			// If the details view was active, we infer a 'close' action was performed on it, so we hide the
			// details view
			if(this._detailsView)
			{
				this.hideProjectDetails().then(() => this.dispatchViewportUpdate());
				this.highlightPictureView(null);
			}
		}
	}

	public onSectionSettled():void
	{
		if(this._cuedProjectCode)
		{
			this.dispatchLoadProjectDetailsByCode(this._cuedProjectCode);
			this._cuedProjectCode = null;
		}
	}

	private dispatchLoadProjectDetailsByCode(projectCode:string):void
	{
		CommandSignals.loadProjectDetails.dispatch({project: this.collection.findWhere({code: projectCode})});
	}

	private onPopulateProjectDetails(project:Project, projectDetails:Backbone.Model):void
	{
		ViewSignals.switchingProjectDetails.dispatch(true);

		if(this._detailsView)
			this.hideProjectDetails().then(() => { this.showProjectDetails(project, projectDetails); });
		else
			this.showProjectDetails(project, projectDetails);
	}

	private onPopulateProjects(projects:Backbone.Collection<Project>):void
	{
		this.collection = projects;
		this.populateProjects();
	}

	private onScrollArrowClick():void
	{
		ViewSignals.goToSection.dispatch(SectionType.CONTACT);
	}

	private onInitComplete():void
	{
		ViewSignals.goToSection.add(this.onGoToSection, this);
	}

	// Private

	private populateProjects():void
	{
		var numProjectsPerRow = this._screenSize < ScreenSize.MEDIUM? 2 : 3;

		var numProjects = this.collection.length;
		var numPictures;

		// Make sure there are enough pictures to fill every row, even if there aren't enough projects for pictures
		if(numProjects % numProjectsPerRow)
			numPictures = Math.floor(numProjects / numProjectsPerRow) * numProjectsPerRow + numProjectsPerRow;
		else
			numPictures = numProjects;

		for(var i = 0; i < numPictures; i += numProjectsPerRow)
		{
			var rowProjectCollection = new ProjectCollection(this.collection.slice(i, i + numProjectsPerRow));
			var rowView = new ProjectRowView({collection: rowProjectCollection, numViewsPerRow: numProjectsPerRow});

			this._$content.append(rowView.render().$el);
			this._rowViews.push(rowView);
			this._pictureViews = this._pictureViews.concat(rowView.pictureViews);
		}
	}

	/**
	 * @param pictureView   Picture view to leave highlighted. Pass null to highlight all.
	 */
	private highlightPictureView(pictureView:ProjectPictureView):void
	{
		this._pictureViews.forEach(
			(view:ProjectPictureView) =>
			{
				view.deHighlight(pictureView? view !== pictureView : false);
				view.timeImages(pictureView? view === pictureView : false);
			});
	}

	private showProjectDetails(project:Project, projectDetails:Backbone.Model):void
	{
		// Find the element associated with the specified project. Get its parent row. Insert a project details view
		// after this row, passing in a merge of the project attributes and other associated project data.

		this._detailsView = new ProjectDetailsView(
			{
				model: new Backbone.Model(_.extend(projectDetails.toJSON(), project.toJSON()))
			});

		this._$content
			.find(`.picture-code-${project.get("code")}`)
			.parent()
			.after(this._detailsView.render().$el);

		this._detailsView.show(true).then(() => { ViewSignals.switchingProjectDetails.dispatch(false); });

		ViewSignals.scrollToElement.dispatch(this.getPictureViewByProject(project).$el, {alignBelowNav: true});
	}

	private hideProjectDetails():Q.Promise
	{
		var deferred = Q.defer();

		this._detailsView.show(false).then(() =>
		{
			this._detailsView.remove();
			this._detailsView = null;
			deferred.resolve(null);
		});

		return deferred.promise;
	}

	private getPictureViewByProject(project:Project):ProjectPictureView
	{
		return this._pictureViews .filter((view:ProjectPictureView) => { return view.model === project; })[0];
	}

	// Overrides

	public get sectionURL():string
	{
		return SectionType[SectionType.PROJECTS].toLowerCase();
	}

	// Destroy

	remove(): Backbone.View<any>
	{
		ViewSignals.highlightProject.remove(this.onHighlightProject);
		ViewSignals.populateProjectDetails.remove(this.onPopulateProjectDetails, this);
		ViewSignals.goToSection.remove(this.onGoToSection);
		ViewSignals.screenSizeChange.remove(this.onScreenSizeChange);

		return super.remove();
	}
}

export = ProjectsView;