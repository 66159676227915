/**
 * Created by nick on 21/12/2015.
 */

import SignalMapping = require("./SignalMapping");
import Signal = require("../signals/Signal");

class MVCContext
{
    private _singletonMappings:any;

    public map(signal:Signal):SignalMapping
    {
        return new SignalMapping(signal, this);
    }

    public mapSingletons(mappings:any):void
    {
        this._singletonMappings = mappings;
        this._singletonMappings.context = this;

	    // Apply any existing injections to the singletons just mapped
        for(var o in mappings) this.applyInjections(mappings[o]);
    }

    public applyInjections(target:any):void
    {
        for(var o in this._singletonMappings) if(target.hasOwnProperty(o)) target[o] = this._singletonMappings[o];
    }
}

export = MVCContext;