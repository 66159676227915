/**
 * Created by nick on 03/01/2016.
 */

import _ = require("underscore");
import Backbone = require("backbone");
import ViewSignals = require("../setup/ViewSignals");
import Constants = require("../setup/Constants");
import SectionType = require("../types/SectionType");
import View = Backbone.View;

var template = require("../../templates/navigation.mustache");

class NavigationView extends Backbone.View<any>
{
	private _dropDownShowing = false;
	private _barHeight:number;
	private _fullHeight:number;

	// Initialisation

    constructor()
    {
		super(
			{
				className: "nav",
				events:
				{
					"click .menu-button": "onMenuButtonClick"
				}
			}
		);
	}

	public initialize():void
	{
		ViewSignals.initSections.addOnce(this.onInitSections, this);
		ViewSignals.showNav.add(this.onShow, this);
		ViewSignals.appClick.add(this.onAppClick, this);
	}

	// Render

    public render():NavigationView
    {
	    this.$el.append(template({sections: this.collection.toJSON()}));

	    requestAnimationFrame(() =>
	    {
		    this._barHeight = this.$el.find(".bar").outerHeight();
		    this._fullHeight = this._barHeight + this.$el.find(".dropdown").outerHeight();

	        this.$el
		        .outerHeight(0)
		        .addClass(Constants.CSS_CLASS_TWEEN_HEIGHT);
	    });

	    return this;
    }

	// Handlers

	private onInitSections(sections:Backbone.Collection<any>):void
	{
		sections =
			new Backbone.Collection(sections.filter((section:Backbone.Model) =>
				{ return section.get("sectionType") !== SectionType.LOGO; }));

		this.collection = sections;
	}

	private onMenuButtonClick(event):void
	{
		event.originalEvent.stopPropagation();
		this.showDropDown(true);
	}

	private showDropDown(show:boolean):void
	{
		this.$el.outerHeight(show? this._fullHeight : this._barHeight);
		this._dropDownShowing = show;
	}

	private onShow(show:boolean):void
	{
		this.$el.outerHeight(show? this._barHeight : 0);
	}

	private onAppClick():void
	{
		if(this._dropDownShowing) this.showDropDown(false);
	}

	// Destruction

	public remove():View<any>
	{
		ViewSignals.showNav.remove(this.onShow);
		ViewSignals.appClick.remove(this.onAppClick);
		return super.remove();
	}
}

export = NavigationView;