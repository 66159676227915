import _ = require("underscore");
import Backbone = require("backbone");
import SectionView = require("./SectionView");
import CommandSignals = require("../setup/CommandSignals");
import ViewSignals = require("../setup/ViewSignals");
import SectionType = require("../types/SectionType");
import ParallaxController2 = require("../ui/controllers/ParallaxController2");
import SpaceshipController = require("../ui/controllers/SpaceshipController");
import SatelliteBeamController = require("../ui/controllers/SatelliteBeamController");
import CometController = require("../ui/controllers/CometController");
import UIUtils = require("../utils/UIUtils");
import ScreenSize = require("../types/ScreenSize");
import View = Backbone.View;
import GraphicController = require("../ui/controllers/GraphicController");
import satelliteBeamSVGJSONString from "../../svg/satellite-beam-svg.json";
import cometSVGJSON from "../../svg/comet-svg.json";

var template = require("../../templates/about-section.mustache");

class AboutSectionView extends SectionView
{
	private _monitorScrollPosChange = true;
    private _frameAnimID:number;
    private _animTimestamp:number;
	private _numAnimControllers = 0;
	private _scrollProportion:number;
    private _animControllers:GraphicController[] = [];
	private _articleParallaxController:ParallaxController2;
	private _saturnParallaxController:ParallaxController2;

	// Initialisation

    constructor()
    {
		super(
			{
				className: "about-section",
				events: {
					"click .scroll-arrow": "onScrollArrowClick"
				}
			}
		);
	}

    initialize()
    {
        _.bindAll(this, "onAnimateFrame");
	    ViewSignals.populateContent.add(this.onContentReady, this);
	    ViewSignals.autoScrollingToSection.add(this.onAutoScrollingToSection, this);
	    super.initialize();
    }

    // Render

    render():AboutSectionView
    {
	    this.$el.append(template());

        this.addAnimController(new SpaceshipController(this.$el.find(".spaceship")));
	    this.initSVG();
	    return this;
    }

    // Handlers

	public onSectionInView():void
    {
	    ViewSignals.showNav.dispatch(true);
        this._frameAnimID = requestAnimationFrame(this.onAnimateFrame);
    }

    public onSectionOutOfView():void
    {
        cancelAnimationFrame(this._frameAnimID);
    }

    private onAnimateFrame(timestamp):void
    {
        if(this._animTimestamp)
        {
            var delta = timestamp - this._animTimestamp;
            for(var i = this._numAnimControllers; -- i >= 0; ) this._animControllers[i].update(delta);
        }

        this._animTimestamp = timestamp;
        this._frameAnimID = requestAnimationFrame(this.onAnimateFrame);
    }

    private onContentReady(sectionType:SectionType, html:string):void
    {
	    if(sectionType === SectionType.ABOUT)
	    {
		    var $article = this.$el.find("article");
		    $article.html(html);
		    this._articleParallaxController = new ParallaxController2($article, -0.3, 0.1);
		    this._saturnParallaxController = new ParallaxController2(this.$el.find(".saturn"), -0.2, 0.2);
	    }
    }

	private onAutoScrollingToSection(autoScrolling:boolean):void
	{
		this._monitorScrollPosChange = !autoScrolling;
	}

    public onScrollUpdate(scrollProportion:number):void
    {
	    if(this._articleParallaxController) this._articleParallaxController.update(scrollProportion);
	    if(this._saturnParallaxController) this._saturnParallaxController.update(scrollProportion);

	    if(this._monitorScrollPosChange)
	    {
		    // If this section is partially visible but clipped off the bottom of the screen or clipped off the top of
		    // the screen but at least half visible, auto-scroll to it
		    //if((scrollProportion < 0 && scrollProportion > this._scrollProportion) ||
			 //   (scrollProportion > 0 && scrollProportion < 0.5 && scrollProportion < this._scrollProportion))
		    //{
			 //   this._monitorScrollPosChange = false;
			 //   ViewSignals.goToSection.dispatch(SectionType.ABOUT);
		    //}
	    }

	    this._scrollProportion = scrollProportion;
    }

	public onSectionSettled():void
	{
		this._monitorScrollPosChange = true;
	}

	private onScrollArrowClick():void
	{
		ViewSignals.goToSection.dispatch(SectionType.PROJECTS);
	}

	// Private

    private initSVG():void
    {
	    const INITIAL_BEAM_INTERVALS = [3000, 8000];
	    const BEAM_INTERVALS = [8000, 11000];

	    for(var numBeams = BEAM_INTERVALS.length, i = 0; i < numBeams; i ++)
		    this.initSatelliteBeam(satelliteBeamSVGJSONString, i, INITIAL_BEAM_INTERVALS[i], BEAM_INTERVALS[i]);

	    this.initComet(cometSVGJSON);
    }

	private initSatelliteBeam(svgJSON, beamIndex:number, initialBeamInterval:number, beamInterval:number):void
	{
		const SVG_WIDTH = 280;
		const SVG_HEIGHT = 276;
		const SVG_FPS = 24;

		var svgAnim = new SVGAnim(svgJSON, SVG_WIDTH, SVG_HEIGHT, SVG_FPS);
		var $svg = UIUtils.initCreatedSVG(SVG_WIDTH, SVG_HEIGHT, 15, "xMinYMax");
		var controller = new SatelliteBeamController(svgAnim, initialBeamInterval, beamInterval);

		this.$el.find(".proportional-container").append($svg);
		$svg[0].setAttribute("class", `beam-${beamIndex}`);
		this.addAnimController(controller);
	}

	private initComet(svgJSON):void
	{
		const CSS_CLASS_COMET = "comet";
		const SVG_WIDTH = 470;
		const SVG_HEIGHT = 98;
		const SVG_FPS = 24;

		var svgAnim = new SVGAnim(svgJSON, SVG_WIDTH, SVG_HEIGHT, SVG_FPS);
		var $svg = UIUtils.initCreatedSVG(SVG_WIDTH, SVG_HEIGHT, 8, "xMinYMax");

		this.$el.prepend($svg);
		$svg.attr("class", CSS_CLASS_COMET);

		this.addAnimController(new CometController(svgAnim, $svg));
	}

	private addAnimController(controller):void
	{
		this._animControllers.push(controller);
		this._numAnimControllers = this._animControllers.length;
	}

	// Overrides

	public get sectionURL():string
	{
		return SectionType[SectionType.ABOUT].toLowerCase();
	}

	// Destruction

	public remove():View<any>
	{
		this._animControllers.forEach((controller:GraphicController) => { controller.dispose(); });
		ViewSignals.populateContent.remove(this.onContentReady);
		ViewSignals.autoScrollingToSection.remove(this.onAutoScrollingToSection);
		return super.remove();
	}
}

export = AboutSectionView;