import Signal = require("../signals/Signal");

class Utils
{
	public static dispatchAsyncSignal(signal:Signal, signalParams:any = {}):Q.Promise
	{
		var deferred = Q.defer();
		signalParams.deferredTask = deferred;
		signal.dispatch(signalParams);
		return deferred.promise;
	}
}

export = Utils;