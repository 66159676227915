import ICommand = require("./ICommand");
import MVCContext = require("./MVCContext");
import Signal = require("../signals/Signal");

class SignalMapping
{
    constructor(private _signal:Signal, private _context:MVCContext) {}

    to(command:ICommand)
    {
        this._context.applyInjections(command);

        this._signal.add((parameters) =>
        {
            for(var o in parameters)
            {
                if(command.hasOwnProperty(o))
                    command[o] = parameters[o];
                else
                    throw new Error("MVC: Missing mapping for parameter '" + o + "'");
            }

            command.execute();
        });
    }
}

export = SignalMapping;