/**
 * Created by nick on 21/12/2015.
 */

import Backbone = require("backbone");
import _ = require("underscore");
import $ = require("jquery");
import Constants = require("../setup/Constants");
import CommandSignals = require("../setup/CommandSignals");
import ViewSignals = require("../setup/ViewSignals");
import SectionType = require("../types/SectionType");
import LogoSectionView = require("./LogoSectionView2");
import AboutSectionView = require("./AboutSectionView");
import ProjectsSectionView = require("./ProjectsView");
import SectionView = require("./SectionView");
import NavigationView = require("./NavigationView");
import ContactSectionView = require("./ContactSectionView");
import View = Backbone.View;
import AppFooterView = require("./AppFooterView");
import VideoPanel = require("./VideoPanel");

var template = require("../../templates/application.mustache");

class Application extends Backbone.View<any>
{
	private _switchingProjectDetails;
    private _numSections;
	private _viewportTop;
    private _windowHeight:number;
	private _sectionViewClasses;
	private _sectionViews:SectionView[];
	private _navView;
	private _videoPanel;
	private _$container;
	private _$fixedContainer;

	// Initialisation

	constructor(options?)
	{
		super(options);

		this._switchingProjectDetails = false;
		this._numSections = 0;
		this._viewportTop = 0;
	}

    initialize()
    {
		require("../../css/styles.scss");

		_.bindAll(this, "onScroll", "onResize");

		this._sectionViewClasses =
	    [
		    LogoSectionView,
		    AboutSectionView,
		    ProjectsSectionView,
		    ContactSectionView
	    ];

        this._sectionViews = [];

	    $(document).on("scroll", this.onScroll);
        $(window).on("resize", this.onResize);

	    ViewSignals.scrollToElement.add(this.onScrollToElement, this);
	    ViewSignals.switchingProjectDetails.add(this.onSwitchingProjectDetails, this);
	    ViewSignals.showVideoPanel.add(this.onShowVideoPanel, this);
	    ViewSignals.appClick.add(this.onAppClick, this);

	    ViewSignals.initComplete.addOnce(this.onInitComplete, this);
        CommandSignals.init.dispatch();

        this.$el.on("click", this.onClick);
    }

    // Render

    render()
    {
		this.$el.append(template());
	    this._$container = this.$el.find("." + Constants.CSS_CLASS_CONTAINER);
	    this._$fixedContainer = this.$el.find("." + Constants.CSS_CLASS_FIXED_CONTAINER);

	    return this;
    }

    postRender():void
    {
	    this.measure();

	    ViewSignals.initNav.addOnce(this.onInitNav, this);
	    ViewSignals.initSections.addOnce(this.onInitSections, this);
    }

    // Private

    private initSection(section:Backbone.Model):void
    {
        var sectionViewClass = this._sectionViewClasses[section.get("sectionType")];
        var sectionView:SectionView = new sectionViewClass();
        var $sectionViewEl = sectionView.render().$el;
        this._$container.append($sectionViewEl);
        this._sectionViews.push(sectionView);
        this._numSections ++;
    }

    private measure():void
    {
        this._windowHeight = $(window).height();
    }

	private getSectionViewByType(type:SectionType):SectionView
	{
		return this._sectionViews[type];
	}

	private scrollTo(destViewportTop:number):Q.Promise
	{
		var $window = $(window);
		var scrollRef = {position: $window.scrollTop()};
		var deferred = Q.defer();

		TweenMax.to(scrollRef, 1,
			{
				position: destViewportTop,
				onUpdate: () => { $window.scrollTop(scrollRef.position); },
				onComplete: () => { deferred.resolve(null); }
			});

		return deferred.promise;
	}

	private scrollToElement($element, options?:{alignBelowNav: boolean}):Q.Promise
	{
		var destOffset = $element.offset().top;
		if(options && options.alignBelowNav) destOffset -= this._navView.$el.height() + 15;
		return this.scrollTo(destOffset);
	}

	// Handlers

    private onInitNav()
    {
	    this._navView = new NavigationView();
	    this._$fixedContainer.append(this._navView.render().$el);
    }

    private onShowVideoPanel(show:boolean):void
	{
		if(show)
		{
			this._videoPanel = new VideoPanel();
			this._$fixedContainer.append(this._videoPanel.render().$el);
		}
		else
		{
			this._videoPanel.remove();
			this._videoPanel = null;
		}
	}

    private onInitSections(sections)
    {
        sections.forEach(function(section)
        {
            this.initSection(section);
        }, this);

	    this.dispatchViewportUpdate(false);
    }

	private onInitComplete():void
	{
		this._$container.append(new AppFooterView().render().$el);
		ViewSignals.goToSection.add(this.onGoToSection, this);
	}

	public onGoToSection(type:SectionType, suppressScrollToSection:boolean):void
	{
		if(!suppressScrollToSection)
		{
			var destSection = this.getSectionViewByType(type);

			ViewSignals.autoScrollingToSection.dispatch(true);

			this.scrollToElement(destSection.$el).then(() =>
			{
				destSection.onSectionSettled();
				ViewSignals.autoScrollingToSection.dispatch(false);
			});
		}
	}

	private onScrollToElement($element, options?:{alignBelowNav: boolean}):void
	{
		this.scrollToElement($element, options);
	}

	private onSwitchingProjectDetails(switching:boolean):void
	{
		this._switchingProjectDetails = switching;
	}

	private onAppClick()
	{
		if(this._videoPanel)
			ViewSignals.showVideoPanel.dispatch(false);
	}

	private dispatchViewportUpdate(updateAddressBar:boolean):void
	{
		ViewSignals.viewportUpdate.dispatch(
			this._viewportTop, this._viewportTop + this._windowHeight, updateAddressBar);
	}

    private onScroll()
    {
	    // We only notify the views about a change in the viewport scroll position if the project details in the
	    // projects section aren't currently being switched - that is, the project details panel isn't being opened or
	    // closed. The reason for this is that the opening and closure of this panel can cause the view to momentarily
	    // scroll into a section that isn't the projects section, which causes the URL to be updated to that section:
	    // this doesn't look nice and also screws with the internal navigation logic.
	    if(!this._switchingProjectDetails)
	    {
		    var viewportTop = window.pageYOffset;
		    this.dispatchViewportUpdate(true);
		    this._viewportTop = viewportTop;
	    }
    }

    private onResize():void
    {
		ViewSignals.notifyResize.dispatch();
    }

	private onClick():void
	{
		ViewSignals.appClick.dispatch();
	}

	// Destruction

	public remove():View<any>
	{
		ViewSignals.goToSection.remove(this.onGoToSection, this);
		ViewSignals.scrollToElement.remove(this.onScrollToElement, this);
		ViewSignals.switchingProjectDetails.remove(this.onSwitchingProjectDetails, this);
		ViewSignals.showVideoPanel.remove(this.onShowVideoPanel, this);
		ViewSignals.appClick.remove(this.onAppClick, this);

		this.$el.off("click", this.onClick);

		return super.remove();
	}
}

export = Application;