import Signal = require("../signals/Signal");
/**
 * Created by nick on 21/12/2015.
 */

var CommandSignals =
{
    init: new Signal(),
    initNav: new Signal(),
    initSections: new Signal(),
    loadSectionContent: new Signal(),
    loadProjects: new Signal(),
    loadProjectDetails: new Signal(),
    loadDataMaps: new Signal(),
	initRouter: new Signal(),
    updateURL: new Signal()
};

export = CommandSignals;