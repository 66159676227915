import _ = require("underscore");
import Backbone = require("backbone");
import Project = require("./Project");

class ProjectCollection extends Backbone.Collection<Project>
{
	url = "data/projects.json";

	constructor(options?)
	{
		super(_.extend(options,
			{
				model: Project
			}
		));
	}
}

export = ProjectCollection;