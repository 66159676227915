import $ = require("jquery");
import Constants = require("../../setup/Constants");
import GraphicController = require("./GraphicController");


class BotController extends GraphicController
{
	private _timeSinceLastMove = 0;
	private _moveDelay = 1500;
	private _windowWidth:number;
	private _transformObject = {x: 0};
	private _$botImg;

	constructor(private _$target)
	{
		super();
		this._$botImg = _$target.find("img");
		this.measure();
	}

	private measure():void
	{
		this._windowWidth = $(window).width();
	}

	public update(delta):void
	{
		this._timeSinceLastMove += delta;

		if(this._timeSinceLastMove > this._moveDelay)
		{
			this._timeSinceLastMove = 0;
			this._moveDelay = Math.max(1000, Math.random() * 5000);

			var targetX = this._windowWidth * Math.random();

			this._$botImg.css(Constants.CSS_PROP_TRANSFORM, `scale(${targetX < this._transformObject.x? -1 : 1}, 1)`);

			this._transformObject.x = targetX;

			this._$target.css(Constants.CSS_PROP_TRANSFORM, `translate(${this._transformObject.x}px, 0)`);
		}
	}
}

export = BotController;