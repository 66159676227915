import DataLoadService = require("../services/DataLoadService");
import ViewSignals = require("../setup/ViewSignals");
import Project = require("../models/Project");
import TechnologyMap = require("../models/TechnologyMap");
import PlatformMap = require("../models/PlatformMap");
import ClientMap = require("../models/ClientMap");

class LoadProjectDetailsCommand
{
	// Injected parameters
	public project:Project = null;

	// Dependency injections
	public dataLoadService:DataLoadService = null;
	public technologyMap:TechnologyMap = null;
	public platformMap:PlatformMap = null;
	public clientMap:ClientMap = null;

	public execute():void
	{
		this.dataLoadService.load(`content/projects/${this.project.get("code")}.html`).then((html) =>
		{
			ViewSignals.populateProjectDetails.dispatch(
				this.project,
				new Backbone.Model(
					{
						html: html,
						technologies: this.project.get("technologyIDs").map((id:string) =>
						{
							return this.technologyMap.get(id);
						}),
						platforms: this.project.get("platformIDs").map((id:string) =>
						{
							return this.platformMap.get(id);
						}),
						client: this.clientMap.get(this.project.get("clientID"))
					}));
		});
	}
}

export = LoadProjectDetailsCommand;