import $ = require("jquery");

class UIUtils
{
	public static initCreatedSVG(width:number, height:number, widthPercentage:number, pivot:string)
	{
		var $svg = $("body > svg");
		var svg = $svg[0];
		svg.setAttribute("width", widthPercentage + "%");
		svg.setAttribute("height", widthPercentage + "%");
		svg.setAttribute("viewBox", "0 0 " + width + " " + height);
		svg.setAttribute("preserveAspectRatio", pivot);
		return $svg;
	}

	/**
	 * Provides a small delay before calling the supplied handler. Useful when an element's CSS property is set,
	 * a transition for that property is then added, and the property is immediately set, for example to its end point.
	 *
	 * @param handler
	 */
	public static delayCSSChange(handler:() => void)
	{
		setTimeout(handler, 100);
	}
}

export = UIUtils;