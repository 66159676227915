import $ = require("jquery");
import _ = require("underscore");
import SectionType = require("../types/SectionType");
import ViewSignals = require("../setup/ViewSignals");
import DataLoadService = require("../services/DataLoadService");

class LoadSectionContentCommand
{
    // Injected parameters
	public sectionType:SectionType = null;
	public deferredTask:Q.Deferred = null;

    // Other injections
    public dataLoadService:DataLoadService = null;

    public execute():void
    {
        this.dataLoadService.load(`content/${SectionType[this.sectionType].toLowerCase()}-text.html`)
            .then((data) =>
            {
	            ViewSignals.populateContent.dispatch(this.sectionType, data);
	            this.deferredTask.resolve(null);
            });
    }
}

export = LoadSectionContentCommand;