/**
 * Created by nick on 14/01/2016.
 */

var Constants =
{
	CSS_EVENTS_TRANSITION_END: "webkitTransitionEnd transitionend msTransitionEnd oTransitionEnd",
	CSS_PROP_TRANSFORM: "transform",
	CSS_PROP_PADDING: "padding",
	CSS_PROP_PADDING_TOP: "padding-top",
	CSS_PROP_PADDING_BOTTOM: "padding-bottom",
	CSS_PROP_MARGIN_LEFT: "margin-left",

	CSS_PROP_LEFT: "left",
	CSS_PROP_TOP: "top",
	CSS_PROP_WIDTH: "width",

	CSS_CLASS_CONTAINER: "container",
	CSS_CLASS_FIXED_CONTAINER: "fixed-container",
	CSS_CLASS_TWEEN_HEIGHT: "tween-height",
	CSS_CLASS_TWEEN_PADDING: "tween-padding",

	CSS_STANDARD_MARGIN: 15
};

export = Constants;