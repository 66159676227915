import MVCContext = require("../mvc/MVCContext");
import CommandSignals = require("./CommandSignals");

import MainRouter = require("../setup/MainRouter");
import InitCommand = require("../commands/InitCommand");
import InitRouterCommand = require("../commands/InitRouterCommand");
import InitSectionsCommand = require("../commands/InitSectionsCommand");
import LoadSectionContentCommand = require("../commands/LoadSectionContentCommand");
import LoadProjectsCommand = require("../commands/LoadProjectsCommand");
import InitNavigationCommand = require("../commands/InitNavigationCommand");
import LoadDataMapsCommand = require("../commands/LoadDataMapsCommand");
import LoadProjectDetailsCommand = require("../commands/LoadProjectDetailsCommand");
import DataLoadService = require("../services/DataLoadService");
import TechnologyMap = require("../models/TechnologyMap");
import PlatformMap = require("../models/PlatformMap");
import ProjectCollection = require("../models/ProjectCollection");
import UpdateURLCommand = require("../commands/UpdateURLCommand");
import ScreenSizeMonitor = require("../services/ScreenSizeMonitor");
import ClientMap = require("../models/ClientMap");
/**
 * Created by nick on 21/12/2015.
 */

class MVCSetup
{
    public setUp()
    {
        var context = new MVCContext();

        context.mapSingletons(
            {
	            screenSizeMonitor: new ScreenSizeMonitor(),
                dataLoadService: new DataLoadService(),
                technologyMap: new TechnologyMap(),
	            platformMap: new PlatformMap(),
	            clientMap: new ClientMap(),
	            projectCollection: new ProjectCollection(),
	            router: new MainRouter()
            }
        );

        context.map(CommandSignals.init).to(new InitCommand());
	    context.map(CommandSignals.initRouter).to(new InitRouterCommand());
        context.map(CommandSignals.initNav).to(new InitNavigationCommand());
        context.map(CommandSignals.initSections).to(new InitSectionsCommand());
        context.map(CommandSignals.loadSectionContent).to(new LoadSectionContentCommand());
        context.map(CommandSignals.loadProjects).to(new LoadProjectsCommand());
        context.map(CommandSignals.loadProjectDetails).to(new LoadProjectDetailsCommand());
	    context.map(CommandSignals.loadDataMaps).to(new LoadDataMapsCommand());
		context.map(CommandSignals.updateURL).to(new UpdateURLCommand());
    }
}

export = MVCSetup;