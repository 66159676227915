import GraphicController = require("./GraphicController");
/**
 * Created by nick on 08/01/2016.
 */

class SatelliteBeamController extends GraphicController
{
	private _$svg;
	private _timeSinceLastBeam = 0;
	private _lastFrameNo = 0;
	private _beamInterval:number;

	constructor(private _svgAnim, initialBeamInterval:number, private _standardBeamInterval:number)
	{
		super();
		this._beamInterval = initialBeamInterval;
	}

	public update(delta):void
	{
		this._timeSinceLastBeam += delta;

		var movieClip = this._svgAnim.movieclip;

		if(movieClip)
		{
			if(movieClip.m_currentFrameNo < this._lastFrameNo) this._svgAnim.pause();
			this._lastFrameNo = movieClip.m_currentFrameNo;
		}


		if(this._timeSinceLastBeam > this._beamInterval)
		{
			this._svgAnim.play();
			this._timeSinceLastBeam = 0;
			this._beamInterval = this._standardBeamInterval;
		}
	}

	public get svgAnim()
	{
		return this._svgAnim;
	}
}

export = SatelliteBeamController;