import TechnologyMap = require("../models/TechnologyMap");
import PlatformMap = require("../models/PlatformMap");
import ClientMap = require("../models/ClientMap");

/**
 * Created by nick on 13/01/2016.
 */

class LoadDataMapsCommand
{
	// Parameter injections
	public deferredTask:Q.Deferred = null;

	// Dependency injections
	public technologyMap:TechnologyMap = null;
	public platformMap:PlatformMap = null;
	public clientMap:ClientMap = null;

	public execute():void
	{
		(() =>
		{
			var deferred = Q.defer();
			this.technologyMap.fetch({success: () => { deferred.resolve(null); }});
			return deferred.promise;
		})().then(() =>
		{
			var deferred = Q.defer();
			this.platformMap.fetch({success: () => { deferred.resolve(null); }});
		}).then(() =>
		{
			var deferred = Q.defer();
			this.clientMap.fetch({success: () => { deferred.resolve(null); }});
		}).then(() =>
		{
			this.deferredTask.resolve(null);
		});
	}
}

export = LoadDataMapsCommand;