/**
 * Created by nick on 30/12/2015.
 */

import Backbone = require("backbone");
import ViewSignals = require("../setup/ViewSignals");
import CommandSignals = require("../setup/CommandSignals");
import View = Backbone.View;
import SectionType = require("../types/SectionType");

class SectionView extends Backbone.View<any>
{
    private _inView = false;

	// Initialisation

	public initialize():void
	{
		ViewSignals.viewportUpdate.add(this.onViewportUpdate, this);
	}

	/**
	 * To be overridden by SectionView subclasses.
	 *
	 * @returns {string}    Used to update the URL as appropriate for the section that has just come into view.
	 */
	public get sectionURL():string
	{
		return "";
	}

    // Handlers

	private onViewportUpdate(viewPortTop:number, viewPortBottom:number, updateAddressBar:boolean):void
	{
		var sectionTop = this.$el.offset().top;
		var sectionHeight = this.$el.height();

		if(sectionHeight)
		{
			var sectionBottom = sectionTop + sectionHeight;

			if(this._inView)
			{
				this.onScrollUpdate((viewPortTop - sectionTop) / sectionHeight);

				if(sectionTop > viewPortBottom || sectionBottom < viewPortTop)
				{
					this._inView = false;
					this.notifySectionViewStateChange(false);
				}
			}
			else
			{
				if(!(sectionTop > viewPortBottom || sectionBottom < viewPortTop))
				{
					this._inView = true;
					this.notifySectionViewStateChange(updateAddressBar);
				}
			}
		}
	}

    // Handlers to be implemented by subclasses if necessary

    public onSectionInView():void {}
    public onSectionOutOfView():void {}
    public onScrollUpdate(scrollProportion:number):void {}
	public onSectionSettled():void {}

	// Private

	private notifySectionViewStateChange(updateAddressBar:boolean):void
	{
		if(this._inView)
		{
			if(updateAddressBar) CommandSignals.updateURL.dispatch({url: "#" + this.sectionURL});
			this.onSectionInView();
		}
		else
		{
			this.onSectionOutOfView();
		}
	}

	// Destruction

	public remove():View<any>
	{
		ViewSignals.viewportUpdate.remove(this.onViewportUpdate);
		return super.remove();
	}
}

export = SectionView;