/**
 * Created by nick on 21/01/2016.
 */

enum ScreenSize
{
	EXTRA_SMALL,
	SMALL,
	MEDIUM,
	LARGE
}

export = ScreenSize;