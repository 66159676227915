import _ = require("underscore");
import $ = require("jquery");
import Backbone = require("backbone");
import SectionView = require("./SectionView");
import Constants = require("../setup/Constants");
import ViewSignals = require("../setup/ViewSignals");
import View = Backbone.View;
import SectionType = require("../types/SectionType");

var template = require("../../templates/logo-section.mustache");

class LogoSectionView extends SectionView
{
	private _largeMode = true;
	private _scrollProportion:number;
	private _transformObject = {x: 0, y: 0, scale: 1};
	private _$logo;
	private _$container;

	// Initialisation

	constructor()
    {
		super(
			{
				className: "logo-section",
				events:
				{
					"click .scroll-arrow": "onScrollArrowClick"
				}
			});
	}

	public initialize():void
	{
		_.bindAll(this, "onResize");
		ViewSignals.notifyResize.add(this.onResize);
		super.initialize();
	}

	// Handlers

	private onResize():void
	{
		this.update();
	}

	public onScrollUpdate(scrollProportion:number):void
	{
		if(this._scrollProportion > 0.25 && scrollProportion <= 0.25) ViewSignals.showNav.dispatch(false);

		if(scrollProportion >= 0.5)
		{
			if(this._largeMode)
			{
				this._largeMode = false;
				this.update();
			}
		}
		else
		{
			if(!this._largeMode)
			{
				this._largeMode = true;
				this.update();
			}
		}

		this._scrollProportion = scrollProportion;
	}

	private onScrollArrowClick():void
	{
		ViewSignals.goToSection.dispatch(SectionType.ABOUT);
	}

	// Render

    public render():LogoSectionView
    {
        this.$el.append(template());
	    this._$logo = this.$el.find(".logo");

	    $(document).find("." + Constants.CSS_CLASS_FIXED_CONTAINER).append(this._$logo);

	    this._$container = $(document).find(".container");
	    this.update();

	    return this;
    }

	// Private

	private update():void
	{
		var containerWidth = this._$container.width();
		var windowHeight = $(window).height();

		var logoWidth = containerWidth * 0.8;

		this._$logo.width(logoWidth);

		var logoHeight = this._$logo.height();

		var logoLeft = (containerWidth - logoWidth) * 0.5;
		var logoTop = (windowHeight - logoHeight) * 0.45;

		if(this._largeMode)
		{
			this._transformObject.x = 0;
			this._transformObject.y = 0;
			this._transformObject.scale = 1;
		}
		else
		{
			this._transformObject.x = -logoLeft + 8;
			this._transformObject.y = -logoTop + 8;
			this._transformObject.scale = 1 / (logoHeight / 36);
		}

		this._$logo
			.css(Constants.CSS_PROP_LEFT, logoLeft)
			.css(Constants.CSS_PROP_TOP, logoTop)
			.css(Constants.CSS_PROP_TRANSFORM, `translate(${this._transformObject.x}px, ${this._transformObject.y}px) scale(${this._transformObject.scale})`);
	}

	// Overrides

	public get sectionURL():string
	{
		return "home";
	}

	// Destroy

	public remove():View<any>
	{
		ViewSignals.notifyResize.remove(this.onResize);
		return super.remove();
	}
}

export = LogoSectionView;