/**
 * Created by nick on 26/12/2015.
 */

import Backbone = require("backbone");
import ViewSignals = require("../setup/ViewSignals");
import CommandSignals = require("../setup/CommandSignals");
import SectionType = require("../types/SectionType");

const aboutIconURL = "./svg/nav/about_icon.svg";
const contactIconURL = "./svg/nav/contact_icon.svg";
const projectsIconURL = "./svg/nav/projects_icon.svg";

class InitSectionsCommand
{
    public execute()
    {
        ViewSignals.initSections.dispatch(new Backbone.Collection(
            [
                new Backbone.Model(
	                {
		                sectionType: SectionType.LOGO
	                }),
                new Backbone.Model(
                    {
	                    sectionType: SectionType.ABOUT,
	                    sectionName: "Aboot",
	                    iconSVGData: aboutIconURL,
	                    destURL: "#about"
                    }),
                new Backbone.Model(
	                {
		                sectionType: SectionType.PROJECTS,
		                sectionName: "Les Projets",
		                iconSVGData: projectsIconURL,
		                destURL: "#projects"
	                }),
                new Backbone.Model(
	                {
		                sectionType: SectionType.CONTACT,
		                sectionName: "Kontakt!",
		                iconSVGData: contactIconURL,
		                destURL: "#contact"
	                })
            ]
        ));
    }
}

export = InitSectionsCommand;