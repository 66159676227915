/**
 * Created by nick on 27/12/2015.
 */

enum SectionType
{
    LOGO,
    ABOUT,
    PROJECTS,
    CONTACT
}

export = SectionType;