import $ = require("jquery");
import Constants = require("../../setup/Constants");
import GraphicController = require("./GraphicController");
import UIUtils = require("../../utils/UIUtils");

const MAX_PAUSE_TIME = 4500;

enum SpaceshipMode
{
    NOT_INITIALISED,
    FLYING,
    PAUSING
}

enum SpaceshipDirection
{
    LEFT,
    RIGHT
}

class SpaceshipController extends GraphicController
{
    private _mode = SpaceshipMode.NOT_INITIALISED;
    private _spaceshipDirection = SpaceshipDirection.RIGHT;
    private _minSpaceshipX:number;
    private _windowWidth:number;
    private _windowHeight:number;
    private _pauseTimeSoFar:number;
    private _$spaceship;
    private _transformObject = {x: 0, y: 0, scaleX: 1};

    constructor($spaceship)
    {
        super();
        this._$spaceship = $spaceship;
    }

    private positionSpaceship():void
    {
        switch(this._spaceshipDirection)
        {
            case SpaceshipDirection.LEFT:
                this._transformObject.x = this._windowWidth;
                break;
            case SpaceshipDirection.RIGHT:
                this._transformObject.x = this._minSpaceshipX;
        }

        this._transformObject.y = Math.random() * this._windowHeight * 0.5;
        this._$spaceship.css(
            Constants.CSS_PROP_TRANSFORM,
            `translate(${this._transformObject.x}px, ${this._transformObject.y}px) ` +
            `scale(${this._transformObject.scaleX}, 1)`);
    }

    public update(delta):void
    {
        if(this._mode == SpaceshipMode.NOT_INITIALISED)
        {
            this.measure();
            this.fly();
        }
        else if(this._mode == SpaceshipMode.PAUSING)
        {
            this._pauseTimeSoFar += delta;

            if(this._pauseTimeSoFar > MAX_PAUSE_TIME)
            {
	            this.measure();
	            this.fly();
            }
        }
    }

    private fly():void
    {
	    const CSS_CLASS_FLY_TWEEN = "fly-tween";

	    this.mode = SpaceshipMode.FLYING;

	    switch(this._spaceshipDirection)
	    {
		    case SpaceshipDirection.LEFT:
			    this._transformObject.x = this._minSpaceshipX;
			    break;
		    case SpaceshipDirection.RIGHT:
			    this._transformObject.x = this._windowWidth;
	    }

	    UIUtils.delayCSSChange(() =>
	    {
		    this._$spaceship
			    .addClass(CSS_CLASS_FLY_TWEEN)
			    .on(Constants.CSS_EVENTS_TRANSITION_END, () =>
			    {
				    this._$spaceship
					    .off(Constants.CSS_EVENTS_TRANSITION_END)
					    .removeClass(CSS_CLASS_FLY_TWEEN);
				    this.mode = SpaceshipMode.PAUSING;
			    })
			    .css(
				    Constants.CSS_PROP_TRANSFORM,
                    `translate(${this._transformObject.x}px, ${this._transformObject.y}px) ` +
                    `scale(${this._transformObject.scaleX}, 1)`);

        });
    }

    private measure():void
    {
        this._windowWidth = $(window).width();
        this._windowHeight = $(window).height();
        this._minSpaceshipX = -this._$spaceship.width();
    }

    private set mode(mode:SpaceshipMode)
    {
        switch(mode)
        {
            case SpaceshipMode.FLYING:

                this.positionSpaceship();
                break;

            case SpaceshipMode.PAUSING:

                this._pauseTimeSoFar = 0;
                this._spaceshipDirection = 1 - this._spaceshipDirection;
                this._transformObject.scaleX *= -1;
        }

        this._mode = mode;
    }
}

export = SpaceshipController;