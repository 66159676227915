import Backbone = require("backbone");

var template = require("../../templates/app-footer.mustache");

class AppFooterView extends Backbone.View<any>
{
	constructor()
	{
		super(
			{
				tagName: "footer"
			}
		);
	}

	public render():AppFooterView
	{
		this.$el.append(template());
		return this;
	}
}

export = AppFooterView;